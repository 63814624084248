import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GhostTitleComponent } from './ghost-title.component';

@NgModule({
  declarations: [GhostTitleComponent],
  exports: [GhostTitleComponent],
  imports: [CommonModule],
})
export class GhostTitleModule {}
