import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GhostParagraphComponent } from './ghost-paragraph.component';

@NgModule({
  declarations: [GhostParagraphComponent],
  imports: [CommonModule],
  exports: [GhostParagraphComponent],
})
export class GhostParagraphModule {}
