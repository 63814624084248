import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'x-ghost-paragraph',
  templateUrl: './ghost-paragraph.component.html',
  styleUrls: ['./ghost-paragraph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GhostParagraphComponent {
  paragraphs: number[] = new Array(2);

  @Input() set rows(count: number) {
    this.paragraphs = [...new Array(count)];
  }
}
